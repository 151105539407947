<template>
  <nav>
    <v-app-bar app flat>
      <v-app-bar-nav-icon
        class="grey--text"
        @click="drawer = !drawer"
        v-if="loggedIn"
      ></v-app-bar-nav-icon>
      <v-toolbar-title class="grey--text">
        <span class="font-weight-black">AHC</span>
        <span class="font-weight-light">terdeur</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <h4 class="grey--text" v-if="currentUser">
        <small>Gebruiker:</small> {{ currentUser.name }}
      </h4>
      <v-btn text color="grey" @click="logout">
        <span>Uitloggen</span>
        <v-icon right>mdi-exit-to-app</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer app v-model="drawer" color="#ebb63b">
      <v-img
        src="@/assets/logo_ahc.png"
        contain
        height="120px"
        class="ma-2"
      ></v-img>
      <v-list v-if="currentUser">
        <v-list-item router :to="'/'">
          <v-list-item-action>
            <v-icon class="white--text">mdi-human-male-height</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="white--text"
              >Persoonlijke pagina</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="clubPermission"
          router
          :to="'/club'"
        >
          <v-list-item-action>
            <v-icon class="white--text">mdi-human-queue</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="white--text"
              >Club van 111</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-expansion-panels flat>
        <!-- <v-expansion-panel
          v-if="currentUser && (currentUser.role == 'Admin' || shopPermission)"
        >
          <v-expansion-panel-header color="#ebb63b">
            <v-layout row>
              <v-flex xs3>
                <v-icon class="white--text">mdi-basket-outline</v-icon>
              </v-flex>
              <v-flex xs7>
                <span color="#ebb63b" class="white--text">Webshop</span>
              </v-flex>
            </v-layout>

            <template v-slot:actions>
              <v-icon color="white">$expand</v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content color="#ebb63b" class="white--text">
            <v-list>
              <v-list-item
                v-for="link in webshopLinks"
                :key="link.text"
                router
                :to="link.route"
              >
                <v-list-item-action>
                  <v-icon class="white--text">{{ link.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="white--text">
                    <small>{{ link.text }}</small>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel> -->
        <v-expansion-panel v-if="currentUser">
          <v-expansion-panel-header color="#ebb63b">
            <v-layout row>
              <v-flex xs3>
                <v-icon class="white--text">mdi-monitor</v-icon>
              </v-flex>
              <v-flex xs7>
                <span color="#ebb63b" class="white--text">Krant</span>
              </v-flex>
            </v-layout>

            <template v-slot:actions>
              <v-icon color="white">$expand</v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content color="#ebb63b" class="white--text">
            <v-list>
              <v-list-item
                v-if="
                  currentUser &&
                  ( paperPermission)
                "
                router
                to="/krant_administratie"
              >
                <v-list-item-action>
                  <v-icon class="white--text"
                    >mdi-newspaper-variant-outline</v-icon
                  >
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="white--text">
                    <small>Administratie</small>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="currentUser" router to="/mijn_adverteerders">
                <v-list-item-action>
                  <v-icon class="white--text">mdi-clipboard-list</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="white--text">
                    <small>Mijn advertenties</small>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel
          v-if="
            currentUser &&
            (currentUser.role == 'Admin' || currentUser.role == 'Editor')
          "
        >
          <v-expansion-panel-header color="#ebb63b">
            <v-layout row>
              <v-flex xs3>
                <v-icon class="white--text">mdi-monitor</v-icon>
              </v-flex>
              <v-flex xs7>
                <span color="#ebb63b" class="white--text">Website beheer</span>
              </v-flex>
            </v-layout>

            <template v-slot:actions>
              <v-icon color="white">$expand</v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content color="#ebb63b" class="white--text">
            <v-list>
              <v-list-item
                v-for="link in websiteLinks"
                :key="link.text"
                router
                :to="link.route"
              >
                <v-list-item-action>
                  <v-icon class="white--text">{{ link.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="white--text">
                    <small>{{ link.text }}</small>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header color="#ebb63b">
            <v-layout row>
              <v-flex xs3>
                <v-icon class="white--text">mdi-desktop-mac-dashboard</v-icon>
              </v-flex>
              <v-flex xs7>
                <span color="#ebb63b" class="white--text">Secretatiaat</span>
              </v-flex>
            </v-layout>
            <template v-slot:actions>
              <v-icon color="white">$expand</v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content color="#ebb63b" class="white--text">
            <v-list v-if="currentUser">
              <v-list-item
                v-for="link in secretariaatLinks"
                :key="link.text"
                router
                :to="link.route"
              >
                <v-list-item-action>
                  <v-icon class="white--text">{{ link.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="white--text">
                    <small>{{ link.text }}</small>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                :href="'https://drive.google.com/drive/folders/0B2YvujxBPcjWLU1vcW45cEZfYXc?resourcekey=0--sMFqrQ7whgadNEIcwAGww&usp=sharing'"
                target="_blank"
              >
                <v-list-item-action>
                  <v-icon class="white--text">mdi-camera</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="white--text">
                    <small>Foto bibliotheek</small>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-list v-if="currentUser && currentUser.role == 'Admin'">
              <v-list-item
                v-for="link in secretariaatLinksAdmin"
                :key="link.text"
                router
                :to="link.route"
              >
                <v-list-item-action>
                  <v-icon class="white--text">{{ link.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="white--text">
                    <small>{{ link.text }}</small>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header color="#ebb63b">
            <v-layout row>
              <v-flex xs3>
                <v-icon class="white--text">mdi-lan</v-icon>
              </v-flex>
              <v-flex xs7>
                <span color="#ebb63b" class="white--text">Commissies</span>
              </v-flex>
            </v-layout>
            <template v-slot:actions>
              <v-icon color="white">$expand</v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content color="#ebb63b" class="white--text">
            <v-list v-if="userCommissions">
              <v-list-item
                v-for="wg in userCommissions"
                :key="wg.id"
                router
                :to="`/commissies/${wg.id}`"
              >
                <v-list-item-action>
                  <v-icon class="white--text">mdi-account-hard-hat</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="white--text">
                    <small>{{ wg.index }} - {{ wg.name }}</small>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import firebase from "firebase";
export default {
  name: "Navbar",
  data() {
    return {
      drawer: false,
      websiteLinks: [
        {
          icon: "mdi-newspaper-variant-outline",
          text: "Nieuwsberichten",
          route: "/nieuwsberichten",
        },
        {
          icon: "mdi-music",
          text: "optocht",
          route: "/optocht",
        },
        { icon: "mdi-view-dashboard", text: "Home", route: "/homepage" },
        {
          icon: "mdi-glass-mug-variant",
          text: "Ulster Bierproeffestival",
          route: "/ubpf",
        },
        {
          icon: "mdi-music",
          text: "Liedjesfestival",
          route: "/liedjesfestival",
        },
        {
          icon: "mdi-account-group",
          text: "Jeugdraad",
          route: "/jeugdraad",
        },

        {
          icon: "mdi-newspaper-variant",
          text: "Carnavalskrant",
          route: "/carnavalskrant",
        },
        {
          icon: "mdi-balloon",
          text: "Kindercarnaval",
          route: "/kindercarnaval",
        },
        {
          icon: "mdi-history",
          text: "Over ons",
          route: "/overons",
        },
        {
          icon: "mdi-comment-processing",
          text: "Contact",
          route: "/contact",
        },
        {
          icon: "",
          text: "Dwaile",
          route: "/dwaile",
        },
        {
          icon: "",
          text: "Pagadder",
          route: "/pagadder",
        },
      ],
      webshopLinks: [
        {
          icon: "mdi-cart-arrow-down",
          text: "Overzicht bestellingen",
          route: "/webshop/bestellingen",
        },
        {
          icon: "mdi-cupcake",
          text: "Overzicht producten",
          route: "/webshop/producten",
        },
        {
          icon: "mdi-tune-vertical",
          text: "instellingen",
          route: "/webshop/instellingen",
        },
        {
          icon: "mdi-currency-eur",
          text: "Overzicht financiën",
          route: "/webshop/financien",
        },
      ],
      secretariaatLinks: [
        {
          icon: "mdi-note-multiple",
          text: "Notulen / Agenda's",
          route: "/notulenagendas",
        },
        {
          icon: "mdi-calendar-month",
          text: "Jaarprogramma",
          route: "/jaarprogramma",
        },
        {
          icon: "mdi-clipboard-list",
          text: "Commissielijst",
          route: "/commissielijst",
        },
        {
          icon: "mdi-account-multiple",
          text: "Ledenoverzicht",
          route: "/ledenlijst",
        },
        {
          icon: "mdi-currency-eur",
          text: "Financiën",
          route: "/financien",
        },
      ],
      secretariaatLinksAdmin: [
        {
          icon: "mdi-account-multiple",
          text: "Gebruikers beheer",
          route: "/gebruikers",
        },
      ],
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
    loggedIn() {
      return this.$store.state.loggedIn;
    },
   
    userCommissions() {
      return this.$store.getters["commissionStore/getUserCommissions"](this.currentUser?.id);
    },
    clubPermission() {
      let permission = false;
      if (this.userCommissions) {
        this.userCommissions.forEach((x) => {
          if (x.index === "7") {
            permission = true;
          }
        });
      }
      return permission;
    },
    paperPermission() {
      let permission = false;
      if (this.userCommissions) {
        this.userCommissions.forEach((x) => {
          if (x.index === "2") {
            permission = true;
          }
        });
      }
      return permission;
    },
    shopPermission() {
      let permission = false;
      if (this.userCommissions) {
        this.userCommissions.forEach((x) => {
          if (x.index === "7") {
            permission = true;
          }
        });
      }
      return permission;
    },
  },
  methods: {
    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.push({ name: "Login" });
        });
      this.$store.dispatch("clearCurrentUser");
    },
  },
  created() {
    this.$store.dispatch("loadIntros");
    this.$store.dispatch("loadUsers");
    this.$store.dispatch("commissionStore/loadCommissions");
    console.log("Navbar created", this.currentUser);
    setTimeout(() => {
      this.$store.dispatch("loadWorkgroups");
    }, 5000);
  },
  mounted() {
    console.log("Navbar mounted", this.currentUser);
    console.log("Navbar mounted", this.userCommissions);
  },
};
</script>

<style>
.v-expansion-panel-header {
  width: 100%;
}
</style>