<template>
    <v-container>
      <v-layout row wrap xs12 class="ma-2 justify-space-between">
        <h1 class="ma-4 d-flex">
          <span class="ahcRed">Financieel archief 2020 - 2024</span>
        </h1>
      </v-layout>
      <v-layout row wrap  name="layoutFinancial">
        <v-flex
          xs12
          class="ma-3"
          name="flexAccountancy"
          v-if="accountancy"
        >
          <v-flex xs12 class="ma-3" name="flexAccountancy">
            <v-card>
              <v-system-bar class="pa-4" color="#ebb63b">
                <span class="ahcRed subheading">
                  <b>Boekhouding oude commissies</b>
                </span>
              </v-system-bar>
              <v-tabs color="#cc212f">
                <v-tab
                  v-model="yearTab"
                  v-for="(year, i) in accountancy.years"
                  :key="i"
                  >{{ year.title }}</v-tab
                >
                <v-tab-item v-for="(year, i) in accountancy.years" :key="i">
                  <v-card-text>
                    <v-alert outlined color="#ebb63b">
                      <v-flex xs12 class="ma-2">
                        <v-row>
                          <v-col>
                            <v-card></v-card>
                          </v-col>
                          <v-col>
                            <v-card flat><h3>Uitgaven</h3></v-card>
                          </v-col>
                          <v-col>
                            <v-card flat><h3>Inkomsten</h3></v-card>
                          </v-col>
                          <v-col>
                            <v-card flat><h3>Resultaat</h3></v-card>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-card flat><h3>Begroot</h3></v-card>
                          </v-col>
                          <v-col>
                            <v-card flat
                              ><v-chip color="#ebb63b" text-color="#cc212f"
                                >€{{
                                  year.totalExpencesBudget.toFixed(2)
                                }}</v-chip
                              ></v-card
                            >
                          </v-col>
                          <v-col>
                            <v-card flat
                              ><v-chip color="#ebb63b" text-color="#cc212f"
                                >€{{ year.totalIncomeBudget.toFixed(2) }}</v-chip
                              ></v-card
                            >
                          </v-col>
                          <v-col>
                            <v-card flat
                              ><v-chip color="#ebb63b" text-color="#cc212f"
                                >€{{
                                  (
                                    year.totalIncomeBudget -
                                    year.totalExpencesBudget
                                  ).toFixed(2)
                                }}</v-chip
                              ></v-card
                            >
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-card flat
                              ><h3>Werkelijk</h3>
                              <small>(Tot op heden)</small></v-card
                            >
                          </v-col>
                          <v-col>
                            <v-card flat
                              ><v-chip color="#ebb63b" text-color="#cc212f"
                                >€{{ year.totalExpences.toFixed(2) }}</v-chip
                              ></v-card
                            >
                          </v-col>
                          <v-col>
                            <v-card flat
                              ><v-chip color="#ebb63b" text-color="#cc212f"
                                >€{{ year.totalIncome.toFixed(2) }}</v-chip
                              ></v-card
                            >
                          </v-col>
                          <v-col>
                            <v-card flat
                              ><v-chip color="#ebb63b" text-color="#cc212f"
                                >€{{
                                  (year.totalIncome - year.totalExpences).toFixed(
                                    2
                                  )
                                }}</v-chip
                              ></v-card
                            >
                          </v-col>
                        </v-row>
                      </v-flex>
                    </v-alert>
                    <v-expansion-panels>
                      <v-expansion-panel
                        @change="(editWGBudget = false), (editWGIncome = false)"
                        v-for="(wg, j) in year.workgroups"
                        :key="j"
                      >
                        <v-expansion-panel-header>
                          <div class="d-flex justify-space-between">
                            <b>C{{ wg.index }} - {{ wg.title }}</b>
  
                            <span
                              class="ahcRed"
                              v-if="wg.declarations.open.length > 0"
                              ><b>{{ wg.declarations.open.length }}</b></span
                            >
                          </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="mx-5">
                          <div class="d-flex" >
                            <span
                              >Begrote kosten:
                              <b>€{{ wg.expencesBudget }}</b></span
                            >
                            <v-icon
                              color="grey"
                              size="small"
                              class="mx-3"
                              @click="editWGBudget = true"
                              >mdi-pencil-outline</v-icon
                            >
                          </div>
                    
                          <div class="d-flex" >
                            <span
                              >Begrote inkomsten:
                              <b>€{{ wg.incomeBudget }}</b></span
                            >
                            <v-icon
                              color="grey"
                              size="small"
                              class="mx-3"
                              @click="editWGIncome = true"
                              >mdi-pencil-outline</v-icon
                            >
                          </div>
                     
                          <span
                            >Kosten tot op heden: <b>€{{ (wg.expences).toFixed(2) }}</b
                            ><br
                          /></span>
                          <span
                            >Inkomsten tot op heden: <b>€{{ (wg.income).toFixed(2) }}</b
                            ><br
                          /></span>
                          <span
                            >Begroot resultaat:
                            <b>€{{ (wg.incomeBudget - wg.expencesBudget).toFixed(2) }}</b
                            ><br
                          /></span>
                          <span
                            >Werkelijk resultaat:
                            <b>€{{ (wg.income - wg.expences).toFixed(2) }}</b
                            ><br
                          /></span>
  
                          
                          <v-card class="my-2">
                            <v-system-bar class="pa-4">
                              <span>
                                <b>Overzicht transacties</b>
                              </span>
                            </v-system-bar>
                            <v-expansion-panels flat>
                              <v-expansion-panel>
                                <v-expansion-panel-header>
                                  <span><b>Openstaande declaraties</b></span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                  <v-list
                                    v-if="wg.declarations.open.length > 0"
                                    two
                                    line
                                  >
                                    <v-list-item
                                      v-for="(transaction, k) in wg.declarations
                                        .open"
                                      :key="k"
                                    >
                                      <v-list-item-icon
                                        v-if="transaction.amount < 0"
                                        ><v-icon color="red"
                                          >mdi-arrow-down-bold</v-icon
                                        >
                                      </v-list-item-icon>
                                      <v-list-item-icon
                                        v-if="transaction.amount > 0"
                                        ><v-icon color="green"
                                          >mdi-arrow-up-bold</v-icon
                                        ></v-list-item-icon
                                      >
                                      <v-list-item-icon
                                        v-if="transaction.receiptType"
                                        @click="
                                          downloadReceipt(
                                            transaction.id,
                                            transaction.receiptType,
                                            wg.index
                                          )
                                        "
                                      >
                                        <v-icon>mdi-file-download-outline</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content>
                                        <v-list-item-title
                                          >€{{ transaction.amount.toFixed(2) }}
                                          {{
                                            transaction.description
                                          }}</v-list-item-title
                                        >
                                        <v-list-item-subtitle
                                          ><small
                                            >{{ transaction.creationDate }} -
                                            {{ transaction.creator }}</small
                                          ></v-list-item-subtitle
                                        >
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                              <v-expansion-panel>
                                <v-expansion-panel-header>
                                  <span><b>Afgesloten declaraties</b></span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                  <v-list
                                    v-if="wg.declarations.closed.length > 0"
                                    three
                                    line
                                  >
                                    <v-list-item
                                      v-for="(transaction, k) in wg.declarations
                                        .closed"
                                      :key="k"
                                    >
                                      <v-list-item-icon
                                        v-if="
                                          transaction.amount < 0 &&
                                          !transaction.reason
                                        "
                                        ><v-icon color="red"
                                          >mdi-arrow-down-bold</v-icon
                                        >
                                      </v-list-item-icon>
                                      <v-list-item-icon
                                        v-if="
                                          transaction.amount > 0 &&
                                          !transaction.reason
                                        "
                                        ><v-icon color="green"
                                          >mdi-arrow-up-bold</v-icon
                                        ></v-list-item-icon
                                      >
                                      <v-list-item-icon v-if="transaction.reason"
                                        ><v-icon color="red"
                                          >mdi-close-thick</v-icon
                                        ></v-list-item-icon
                                      >
                                      <v-list-item-icon
                                        v-if="transaction.receiptType"
                                        @click="
                                          downloadReceipt(
                                            transaction.id,
                                            transaction.receiptType,
                                            wg.index
                                          )
                                        "
                                      >
                                        <v-icon>mdi-file-download-outline</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content>
                                        <v-list-item-title
                                          >€{{ transaction.amount.toFixed(2) }}
                                          {{
                                            transaction.description
                                          }}</v-list-item-title
                                        >
                                        <v-list-item-subtitle
                                          v-if="transaction.reason"
                                          ><small
                                            >{{ transaction.reason }}
                                          </small></v-list-item-subtitle
                                        >
                                        <v-list-item-subtitle
                                          ><small
                                            >{{ transaction.creationDate }} -
                                            {{ transaction.creator }}</small
                                          ></v-list-item-subtitle
                                        >
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                          </v-card>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-card-text>
                </v-tab-item>
              </v-tabs>
            </v-card>
          </v-flex>
        </v-flex>
      </v-layout>
      <v-card class="ma-5" elevation="0" >
        <v-row class="d-flex justify-space-around mb-6">
          <v-progress-circular
            class="ma-5 ahcRed"
            :size="70"
            :width="7"
            indeterminate
          ></v-progress-circular>
        </v-row>
        <v-row class="d-flex justify-space-around mb-6">
          <h3>Inladen documenten...</h3>
        </v-row>
      </v-card>
    </v-container>
  </template>
  
  <script>
  import firebase from "firebase";
  import Axios from "axios";
  export default {
    name: "FinancialArchive",
    components: {
    },
    data() {
      return {
        buzzy: false,
        yearTab: 0,
        mutationRadiogroup: 0,
      };
    },
    computed: {
      currentUser() {
        return this.$store.state.currentUser;
      },
      accountancy() {
        return this.$store.state.accountancyArchive;
      },
      userName() {
        return this.$store.state.currentUser.name;
      },
    },
    methods: {
      downloadReceipt(id, file_type, wg) {
        let metadata;
        if (file_type === "doc" || file_type === "dot") {
          metadata = "application/msword";
        } else if (file_type === "docx") {
          metadata =
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        } else if (
          file_type === "xls" ||
          file_type === "xlt" ||
          file_type === "xla"
        ) {
          metadata = "application/vnd.ms-excel";
        } else if (file_type === "xlsx") {
          metadata =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        } else if (
          file_type === "ppt" ||
          file_type === "pot" ||
          file_type === "pps" ||
          file_type === "ppa"
        ) {
          metadata = "application/vnd.ms-powerpoint";
        } else if (file_type === "pptx") {
          metadata =
            "application/vnd.openxmlformats-officedocument.presentationml.presentation";
        } else if (file_type === "mdb") {
          metadata = "application/vnd.ms-access";
        } else if (file_type === "pdf" || file_type === "PDF") {
          metadata = "application/pdf";
        } else if (file_type === "png") {
          metadata = "image/png";
        } else if (
          file_type === "jpg" ||
          file_type === "jpeg" ||
          file_type === "JPG" ||
          file_type === "JPEG"
        ) {
          metadata = "image/jpeg";
        }
  
        let filename = `Factuur_C${wg}_${id}`;
        let file = firebase.storage().ref(`accountancy/C${wg}/${id}`);
        file.getDownloadURL().then((url) => {
          Axios.get(url, { responseType: "blob" })
            .then(({ data }) => {
              const blob = new Blob([data], {
                type: metadata,
              });
  
              const url = document.createElement("a");
              url.href = URL.createObjectURL(blob);
              url.download = filename;
              url.click();
              URL.revokeObjectURL(url.href);
            })
            .catch((error) => alert(error));
        });
      },
    },
    beforeCreate() {
      this.$store.dispatch("loadAccountancyArchive");
    },
  };
  </script>
  
  <style>
  </style>