import Vue from 'vue'
import VueRouter from 'vue-router'
import AddUser from '@/components/auth/AddUser'
import EditUser from '@/components/auth/EditUser'
import Login from '../components/auth/Login'
import Users from '@/components/auth/Users'
import firebase from '@/firebase/init'
import store from '../store/index'
import HomepageBack from '@/components/website/HomepageBack'
import UbpfBack from '@/components/website/UbpfBack'
import CarnavalskrantBack from '@/components/website/CarnavalskrantBack'
import LiedjesfestivalBack from '@/components/website/LiedjesfestivalBack'
import ContactBack from '@/components/website/ContactBack'
import NewsItems from '@/components/website/NewsItems'
import TestArea from '@/components/TestArea'
import Profile from '@/components/Profile'
import Members from '@/components/secretariaat/Members'
import Docs from '@/components/secretariaat/Docs'
//import Workgroups from '@/components/secretariaat/Workgroups'
import Commissions from '@/components/secretariaat/Commissions'
import Calendar from '@/components/secretariaat/Calendar'
import Workgroup from '@/components/Workgroup'
import Financial from '@/components/secretariaat/Financial'
import Club from '@/components/Club'
import OrderOverview from '@/components/webshop/OrderOverview'
import SettingsOverview from '@/components/webshop/SettingsOverview'
import ProductOverview from '@/components/webshop/ProductOverview'
import AddNewProduct from '@/components/webshop/AddNewProduct'
import FinancialOverview from '@/components/webshop/FinancialOverview'
import DwaileBack from '@/components/website/temporary/DwaileBack'
import KindercarnavalBack from '@/components/website/KindercarnavalBack'
import PagadderBack from '@/components/website/temporary/PagadderBack'
import AboutUsBack from '@/components/website/AboutUsBack'
import Zomercarnaval from '@/components/website/Zomercarnaval'
import KrantAdministratie from '@/components/Krant/KrantAdministratie'
import KrantMijnAdvertenties from '@/components/Krant/KrantMijnAdvertenties'
import JeugdraadBack from '@/components/website/Jeugdraad.vue'
import FinancialArchive from '../components/secretariaat/FinancialArchive.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/gebruikertoevoegen',
    name: 'AddUser',
    component: AddUser,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/gebruikers',
    name: 'Users',
    component: Users,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }

  },
  {
    path: '/gebruikeraanpassen/:uid',
    name: 'EditUser',
    component: EditUser,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/contact',
    name: 'ContactBack',
    component: ContactBack,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/homepage',
    name: 'HomepageBack',
    component: HomepageBack,
    meta: {
      requiresAuth: true,
      // requiresAdmin: true
    }
  },
  {
    path: '/ubpf',
    name: 'UbpfBack',
    component: UbpfBack,
    meta: {
      requiresAuth: true,
      // requiresAdmin: true
    }
  },
  {
    path: '/overons',
    name: 'AboutUsBack',
    component: AboutUsBack,
    meta: {
      requiresAuth: true,
      // requiresAdmin: true
    }
  },
  {
    path: '/jeugdraad',
    name: 'JeugdraadBack',
    component: JeugdraadBack,
    meta: {
      requiresAuth: true,
      // requiresAdmin: true
    }
  },
  {
    path: '/testarea',
    name: 'TestArea',
    component: TestArea,
    // meta: {
    //   requiresAuth: true,
    //   // requiresAdmin: true
    // }
  },
  {
    path: '/liedjesfestival',
    name: 'LiedjesfestivalBack',
    component: LiedjesfestivalBack,
    meta: {
      requiresAuth: true,
      // requiresAdmin: true
    }
  },
  {
    path: '/dwaile',
    name: 'DwaileBack',
    component: DwaileBack,
    meta: {
      requiresAuth: true,
      // requiresAdmin: true
    }
  },
  {
    path: '/pagadder',
    name: 'PagadderBack',
    component: PagadderBack,
    meta: {
      requiresAuth: true,
      // requiresAdmin: true
    }
  },
  {
    path: '/kindercarnaval',
    name: 'KindercarnavalBack',
    component: KindercarnavalBack,
    meta: {
      requiresAuth: true,
      // requiresAdmin: true
    }
  },
  {
    path: '/carnavalskrant',
    name: 'CarnavalskrantBack',
    component: CarnavalskrantBack,
    meta: {
      requiresAuth: true,

    }
  },
  {
    path: '/',
    name: 'Profile',
    component: Profile,
    meta: {
      requiresOnlyAuth: true,

    }
  },
  {
    path: '/nieuwsberichten',
    name: 'NewsItems',
    component: NewsItems,
    meta: {
      requiresAuth: true,

    }
  },
  {
    path: '/optocht',
    name: 'Optocht',
    component: Zomercarnaval,
    meta: {
      requiresAuth: true,

    }
  },
  {
    path: '/ledenlijst',
    name: 'Members',
    component: Members,
    meta: {
      requiresAuth: true,

    }
  },
  {
    path: '/notulenagendas',
    name: 'Docs',
    component: Docs,
    meta: {
      requiresAuth: true,

    }
  },
  // {
  //   path: '/commissielijst',
  //   name: 'Workgroups',
  //   component: Workgroups,
  //   meta: {
  //     requiresAuth: true,

  //   }
  // },

  {
    path: '/commissielijst',
    name: 'Commissions',
    component: Commissions,
    meta: {
      requiresAuth: true,

    }
  },
  {
    path: '/jaarprogramma',
    name: 'Calendar',
    component: Calendar,
    meta: {
      requiresAuth: true,

    }
  },
  {
    path: '/financien',
    name: 'Financiën',
    component: Financial,
    meta: {
      requiresAuth: true,

    }
  },
  {
    path: '/financieelarchief',
    name: 'FinancialArchive',
    component: FinancialArchive,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/commissies/:id',
    name: 'Workgroup',
    component: Workgroup,
    meta: {
      requiresAuth: true,
      //requeresAccess: true
    }
  },
  {
    path: '/club',
    name: 'Club',
    component: Club,
    meta: {
      requiresAuth: true,
      //requeresAccess: true
    }
  },
  {
    path: '/webshop/bestellingen',
    name: 'OrderOverview',
    component: OrderOverview,
    meta: {
      requiresAuth: true,
      //requeresAccess: true
    }
  },
  {
    path: '/webshop/producten',
    name: 'ProductOverview',
    component: ProductOverview,
    meta: {
      requiresAuth: true,
      //requeresAccess: true
    }
  },
  {
    path: '/webshop/nieuw_product',
    name: 'AddNewProduct',
    component: AddNewProduct,
    meta: {
      requiresAuth: true,
      //requeresAccess: true
    }
  },
  {
    path: '/webshop/instellingen',
    name: 'SettingsOverview',
    component: SettingsOverview,
    meta: {
      requiresAuth: true,
      //requeresAccess: true
    }
  },
  {
    path: '/webshop/financien',
    name: 'FinancialOverview',
    component: FinancialOverview,
    meta: {
      requiresAuth: true,
      //requeresAccess: true
    }
  },
  {
    path: '/krant_administratie',
    name: 'KrantAdministratie',
    component: KrantAdministratie,
    meta: {
      requiresAuth: true,
      //requeresAccess: true
    }
  },
  {
    path: '/mijn_adverteerders',
    name: 'KrantMijnAdvertenties',
    component: KrantMijnAdvertenties,
    meta: {
      requiresAuth: true,
      //requeresAccess: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,

  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(rec => rec.meta.requiresAdmin)) {
    if (store.state.currentUser.role === 'Admin') {
      next()
    } else {
      next({ name: 'Profile' })
    }
  }
  // If route required authentication
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Load user
    firebase.auth().onAuthStateChanged(user => {
      // If user obj does not exist --> redirect to login page
      if (!user) {
        next({ name: 'Login' });
      } else {
        if (store.state.currentUser == null) {
          store.dispatch("getCurrentUser", user.uid);
          next()
        }


        next();
      }
    });
  }
  if (to.matched.some(record => record.meta.requiresOnlyAuth)) {
    // Load user
    firebase.auth().onAuthStateChanged(user => {
      // If user obj does not exist --> redirect to login page
      if (!user) {
        next({ name: 'Login' });
      } else {
        if (store.state.currentUser == null) {
          store.dispatch("getCurrentUser", user.uid);
        }


        next();
      }
    });
  } else {

    next();

  }
})

export default router
