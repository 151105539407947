
<template>
    <v-data-table :headers="headers" :items="filledContacts" class="elevation-1"  disable-pagination hide-default-footer></v-data-table>
</template>

<script>

export default{
    name: "TotalenPerUser",	
    props: {
        selectedEdition: {
            type: Number,
            default: null
        }
    },
    data(){
        return{
            headers: [{
                text: 'Verkoper',
                align: 'start',
                value: 'name',
            },
            {text: 'Aantal', value: 'totalCount'},
            {text: 'Verkocht', value: 'SoldCount'},
            {text: 'Niet verkocht', value: 'NotSoldCount'},
            {text: 'Open', value: 'OpenCount'},
            {text: 'Opbrengst', value: 'totalProfit'},
        ],
        filledContacts: []
        }
    },
    computed: {
        // contacts(){
        //     let contacts = []
        //     this.advertisers.forEach(advertiser => {
        //         if(!contacts.find(contact => contact.name == advertiser.contact)){
        //             contacts.push({name: advertiser.contact, totalCount: 0, SoldCount: 0, NotSoldCount: 0, OpenCount: 0, totalProfit: 0});
        //         }
        //         let contact = contacts.find(contact => contact.name == advertiser.contact);
        //         contact.totalCount++;
        //         let advertisement = this.relevantAdvertisements.find(advertisement => advertisement.advertiserId == advertiser.id);
        //         if(!advertisement || advertisement.advState == "open"){
        //             contact.OpenCount++
        //         }else if(advertisement.advState == "verkocht"){
        //             contact.SoldCount++;
        //             if(advertisement.advPaymentMethod != "gratis"){
        //                 contact.totalProfit += parseInt(advertisement.advPrice ?? 0) ;
        //             }
        //         }else if(advertisement.advState == "niet verkocht"){
        //             contact.NotSoldCount++;
        //         }
        //     });
        //     return contacts;
        // },
        relevantAdvertisements(){
            if(this.selectedEdition == null || this.selectedEdition == this.$store.state.paperStore.activeEditionId){
                return this.activeAdvertisements ?? [];
            } else {
                let ads = []
                this.archiveAdvertisements.forEach(edition => {
                    if(edition.id == this.selectedEdition){
                        ads = edition.ads
                    }
                });
                return ads;
            }
        },


        activeAdvertisements(){
            return this.$store.state.paperStore.activeAdvertisements;
        },
        archiveAdvertisements(){
            return this.$store.state.paperStore.archiveAdvertisements;
        },


        activeEdition(){
            let activeEditionId = this.$store.state.paperStore.activeEditionId;
            let editions = this.$store.state.paperStore.paperEditions;
            return editions.find(edition => edition.id == activeEditionId);
        },
        advertisers(){
            return this.$store.state.paperStore.advertisers;
        },
    },
    methods: {
        fillContacts(){
            let contacts = []
            this.advertisers.forEach(advertiser => {
                if(!contacts.find(contact => contact.name == advertiser.contact)){
                    contacts.push({name: advertiser.contact, totalCount: 0, SoldCount: 0, NotSoldCount: 0, OpenCount: 0, totalProfit: 0});
                }
                let contact = contacts.find(contact => contact.name == advertiser.contact);
                contact.totalCount++;
                let advertisement = this.relevantAdvertisements.find(advertisement => advertisement.advertiserId == advertiser.id);
                if(!advertisement || advertisement.advState == "open"){
                    contact.OpenCount++
                }else if(advertisement.advState == "verkocht"){
                    contact.SoldCount++;
                    if(advertisement.advPaymentMethod != "gratis"){
                        contact.totalProfit += parseInt(advertisement.advPrice ?? 0) ;
                    }
                }else if(advertisement.advState == "niet verkocht"){
                    contact.NotSoldCount++;
                }
            });
            this.filledContacts = contacts;
        }
    },
    watch: {
        advertisers(){
            this.fillContacts();
        },
        relevantAdvertisements(){
            this.fillContacts();
        }
    },
    created(){
        this.fillContacts();
    }

}
</script>
