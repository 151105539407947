import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router/index'
import firebase from '../firebase/init'
import paperStore from './paperStore.js'
import commissionStore from './commissionStore.js'



Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    paperStore,
    commissionStore
  },
  state: {
    users: null,
    user: {},
    currentUser: null,
    loggedIn: false,
    orders: null,
    newsItems: [],
    newsImages: [],
    intros: {},
    contentJeugdraad: {},
    contentLiedjes: {},
    contentKids: {},
    contentSummer: {},
    contentPaper: {},
    imageDB: [],
    testImage: null,
    contactAdresses: [],
    workgroups: [],
    userWorkgroups: [],
    workgroup: null,
    calendar: [],
    secretary: null,
    accountancy: null,
    liedjesParticipants: null,
    clubMembers: [],
    clubEditions: [],
    jubilee11: 0,
    jubilee22: 0,
    jubilee33: 0,
    jubilee44: 0,
    jubilee55: 0,
    webshop: null,
    productImages: [],
    pagadderSuggestions: {},
    contentAboutUs: {},
    contentHome: {},
    paperFacs: [],
    breweries: [],
    accountancyArchive: null,
  },
  mutations: {


    //User management
    addUser: (state, payload) => {
      let addUser = firebase.functions().httpsCallable("addUser")
      addUser({ user: payload }).then(cred => {
        if (cred.data.created) {

          router.push({ name: "Users" });
        } else {
          console.log(cred.data.msg)
        }
      }).catch((e) => {
        console.log(e)
      })
    },
    loadUsers: (state) => {
      state.users = []
      let loadUsers = firebase.functions().httpsCallable("loadUsers");
      loadUsers().then(result => {
        state.users = result.data.users
      }).catch((e) => {
        console.log('Error in loadUsers: ' + e.message)
      })
    },
    removeUser: (state, payload) => {
      let removeUser = firebase.functions().httpsCallable("removeUser")
      removeUser({ user_id: payload }).then(() => {
        router.go()
      }).catch(r => {
        console.log("error in deleteUser " + r.message)
      })
    },
    getUser: (state, payload) => {
      state.user = {}
      let getUser = firebase.functions().httpsCallable("getUser");
      getUser({ user_id: payload }).then(result => {
        state.user = result.data.user
      }).catch(r => {
        console.log("error in getUser " + r.message)
      })
    },
    getCurrentUser: (state, payload) => {
      state.loggedIn = true
      let getUser = firebase.functions().httpsCallable("getUser");
      getUser({ user_id: payload }).then(result => {
        state.currentUser = result.data.user
      }).catch(r => {
        console.log("error in getUser " + r.message)
      })
    },
    editUser: (state, payload) => {
      let editUser = firebase.functions().httpsCallable("editUser")
      editUser({ user: payload }).then(() => {
        router.push({ name: "Users" });
      }).catch(r => {
        console.log("error in editUser " + r.message)
      })
    },
    editProfile: (state, payload) => {
      let editUser = firebase.functions().httpsCallable("editUser")
      editUser({ user: payload }).then(() => {
        return
      }).catch(r => {
        console.log("error in editUser " + r.message)
      })
    },
    clearCurrentUser: (state) => {
      state.currentUser = null
      state.loggedIn = false
    },
    //UBPF Order management
    loadOrders: (state) => {
      state.orders = []
      let loadOrders = firebase.functions().httpsCallable("loadOrders");
      loadOrders().then(result => {
        state.orders = result.data.orders

      }).catch((e) => {
        console.log('Error in loadOrders: ' + e.message)
      })
    },
    processCollections: () => {
      try {
        let processCollections = firebase.functions().httpsCallable("processingCollection");
        processCollections()
      } catch (error) {
        console.log('Error in processCollections: ' + error.message)
      }
    },
    //NewsItem Management
    addNewsItem: (state, payload) => {
      try {
        state.newsItems = []
        let addNewsItem = firebase.functions().httpsCallable("addNewsItem");
        addNewsItem({ newItem: payload }).then(result => {
          state.newsItems = result.data.newsItems
          state.newsItems.sort((a, b) => {
            return b.id - a.id
          });
        })
      } catch (error) {
        console.log('Error in addNewsItem: ' + error.message)
      }
    },
    getNewsItems: (state) => {
      state.newsItems = []
      let loadNewsItems = firebase.functions().httpsCallable("loadNewsItems");
      loadNewsItems().then(result => {
        state.newsItems = result.data.newsItems
        state.newsItems.sort((a, b) => {
          return b.id - a.id
        });
      }).catch((e) => {
        console.log('Error in loadNewsItems: ' + e.message)
      })
    },
    deleteNewsItem: (state, payload) => {
      let deleteNewsItem = firebase.functions().httpsCallable("deleteNewsItem");
      let deleteRef = firebase.storage().ref(`images/news/${payload}`);
      deleteRef.delete()
      deleteNewsItem({ id: payload })
        .catch((e) => {
          console.log('Error in deleteNewsItem: ' + e.message)
        })
    },
    getNewsImages: (state) => {
      state.newsImages = []
      let storageRef = firebase.storage().ref("images/news/");
      storageRef.listAll().then((result) => {
        result.items.forEach((imageRef) => {
          imageRef.getDownloadURL().then((url) => {
            let decodeUrl = decodeURIComponent(url)
            let parts = decodeUrl.split('/')
            let id = parts[parts.length - 1].split('?')[0];
            state.newsImages[id] = url
          })
        });
      }).catch(function (error) {
        console.log(error)
      });

    },
    //Contact management
    getContactAdresses: (state) => {
      state.contactAdresses = []
      let loadContactAdresses = firebase.functions().httpsCallable("loadContactAdresses");
      loadContactAdresses().then((result) => {
        state.contactAdresses = result.data.contactAdresses
        return
      }).catch((e) => {
        console.log('Error in loadContactAdresses: ' + e.message)
      })
    },
    editContactAdresses: (state, payload) => {
      let editContactAdresses = firebase.functions().httpsCallable("editContactAdresses")
      editContactAdresses({ contactAdresses: payload }).then(() => {
        return
      }).catch(r => {
        console.log("error in editUser " + r.message)
      })
    },
    sendProblem: (state, payload) => {
      try {
        let sendProblem = firebase.functions().httpsCallable("sendProblem");
        sendProblem(payload)
      } catch (error) {
        console.log('error in sendProblem' + error)
      }
    },
    //Intro management
    loadIntros: (state) => {
      state.intros = {}
      let loadIntros = firebase.functions().httpsCallable("loadIntros");
      loadIntros().then(result => {
        state.intros = result.data.intros
        if (state.intros.themeImage) {
          let storageRef = firebase.storage().ref(`images/theme/${state.intros.themeImage.id}`);
          storageRef.getDownloadURL().then((url) => {
            state.intros.themeImage.url = url
          })
        }
      }).catch((e) => {
        console.log('Error in loadIntros: ' + e.message)
      })
    },
    updateIntros: (state, payload) => {
      let updateIntros = firebase.functions().httpsCallable("updateIntros");
      updateIntros({ intro: payload }).then(result => {
        state.intros = result.data.intros
        if (state.intros.themeImage) {
          let storageRef = firebase.storage().ref(`images/theme/${state.intros.themeImage.id}`);
          storageRef.getDownloadURL().then((url) => {
            state.intros.themeImage.url = url
          })
        }
      }).catch((e) => {
        console.log('Error in updateIntros: ' + e.message)
      })
    },
    //content management website
    loadContentJeugdraad: (state) => {
      state.content = {}
      let loadContent = firebase.functions().httpsCallable("loadContentJeugdraad");
      loadContent().then(result => {
        state.contentJeugdraad = result.data.contentJeugdraad
      }).then(() => {
        state.contentJeugdraad.pics.forEach(item => {
          if (item.image) {
            let storageRef = firebase.storage().ref(`images/jeugdraad/carrousel/${item.id}`);
            storageRef.getDownloadURL().then((url) => {
              item.foto = url;
            });
          }
        })
      }).catch((e) => {    
        console.log('Error in contentJeugdraad: ' + e.message)
      })
    },

    updateContentJeugdraad: (state) => {
      let updateContent = firebase.functions().httpsCallable("updateContentJeugdraad");
      updateContent({ content: state.contentJeugdraad }).then(result => {
        state.contentJeugdraad = result.data.contentJeugdraad
      }
      ).then(() => {
        state.contentJeugdraad.pics.forEach(item => {
          if (item.image) {
            let storageRef = firebase.storage().ref(`images/jeugdraad/carrousel/${item.id}`);
            storageRef.getDownloadURL().then((url) => {
              item.foto = url;
            });
          }
        })
      }).catch((e) => {
        console.log('Error in updateContent: ' + e.message)
      }
      )
    },



    loadContentHome: (state) => {
      state.contentHome = {}
      let loadContentHome = firebase.functions().httpsCallable("loadContentHome");
      loadContentHome().then(result => {
        state.contentHome = result.data.contentHome
      }).then(() => {
        state.contentHome.prog.forEach(item => {
          if (item.image) {
            let storageRef = firebase.storage().ref(`images/home/carrouselProg/${item.id}`);
            storageRef.getDownloadURL().then((url) => {
              item.foto = url;
            });
          }
        })
      }).catch((e) => {
        console.log('Error in contentHome: ' + e.message)
      })
    },
    updateContentHome: (state) => {
      let updateContentHome = firebase.functions().httpsCallable("updateContentHome");
      updateContentHome({ content: state.contentHome }).then(result => {
        state.contentHome = result.data.contentHome
      }).then(() => {
        state.contentHome.prog.forEach(item => {
          if (item.image) {
            let storageRef = firebase.storage().ref(`images/home/carrouselProg/${item.id}`);
            storageRef.getDownloadURL().then((url) => {
              item.foto = url;
            });
          }
        })
      }).catch((e) => {
        console.log('Error in updateContentHome: ' + e.message)
      })
    },
    loadContentLiedjes: (state) => {
      state.contentLiedjes = {}
      let loadContentLiedjes = firebase.functions().httpsCallable("loadContentLiedjes");
      loadContentLiedjes().then(result => {
        state.contentLiedjes = result.data.contentLiedjes
      }).catch((e) => {
        console.log('Error in contentLiedjes: ' + e.message)
      })
    },
    updateContentLiedjes: (state, payload) => {
      let updateContentLiedjes = firebase.functions().httpsCallable("updateContentLiedjes");
      updateContentLiedjes(payload).then(result => {
        state.contentLiedjes = result.data.contentLiedjes

      }).catch((e) => {
        console.log('Error in updateContentLiedjes: ' + e.message)
      })
    },
    sendInternalMail: (state, payload) => {
      let sendMail = firebase.functions().httpsCallable("sendInternalMail");
      sendMail(payload).then(result => {
        if (result.data.succes == true) {
          console.log(result.data.msg)
        } else {
          console.log(result.data.msg)
        }
      }).catch(r => {
        console.log("error in sendInternalMail " + r.message)
      })
    },

    loadContentKids: (state) => {
      state.contentKids = {}
      let loadContentKids = firebase.functions().httpsCallable("loadContentKids");
      loadContentKids().then(result => {
        state.contentKids = result.data.contentKids
      }).catch((e) => {
        console.log('Error in contentKids: ' + e.message)
      })
    },
    updateContentKids: (state, payload) => {
      let updateContentKids = firebase.functions().httpsCallable("updateContentKids");
      updateContentKids(payload).then(result => {
        state.contentKids = result.data.contentKids

      }).catch((e) => {
        console.log('Error in updateContentKids: ' + e.message)
      })
    },
    loadContentSummer: (state) => {
      state.contentSummer = {}
      let loadContentSummer = firebase.functions().httpsCallable("loadContentSummer");
      loadContentSummer().then(result => {
        state.contentSummer = result.data.contentSummer
      }).catch((e) => {
        console.log('Error in contentSummer: ' + e.message)
      })
    },
    updateContentSummer: (state, payload) => {
      let updateContentSummer = firebase.functions().httpsCallable("updateContentSummer");
      updateContentSummer(payload).then(result => {
        state.contentSummer = result.data.contentSummer

      }).catch((e) => {
        console.log('Error in updateContentSummer: ' + e.message)
      })
    },
    loadContentAboutUs: (state) => {
      state.contentAboutUs = {}
      let loadContentAboutUs = firebase.functions().httpsCallable("loadContentAboutUs");
      loadContentAboutUs().then(result => {
        state.contentAboutUs = result.data.contentAboutUs
      }).then(() => {
        state.contentAboutUs.carrousel.forEach(item => {
          if (item.image) {
            let storageRef = firebase.storage().ref(`images/aboutUs/carrousel/${item.id}`);
            storageRef.getDownloadURL().then((url) => {
              item.foto = url;
            });
          }
        })
      }).catch((e) => {
        console.log('Error in contentAboutUs: ' + e.message)
      })
    },
    updateContentAboutUs: (state) => {
      let updateContentAboutUs = firebase.functions().httpsCallable("updateContentAboutUs");
      updateContentAboutUs({ content: state.contentAboutUs }).then(result => {
        state.contentAboutUs = result.data.contentAboutUs
      }).then(() => {
        state.contentAboutUs.carrousel.forEach(item => {
          if (item.image) {
            let storageRef = firebase.storage().ref(`images/aboutUs/carrousel/${item.id}`);
            storageRef.getDownloadURL().then((url) => {
              item.foto = url;
            });
          }
        })
      }).catch((e) => {
        console.log('Error in updateContentAboutUs: ' + e.message)
      })
    },
    loadContentPaper: (state) => {
      state.contentPaper = {}
      let loadContentPaper = firebase.functions().httpsCallable("loadContentPaper");
      loadContentPaper().then(result => {
        state.contentPaper = result.data.contentPaper
      }).catch((e) => {
        console.log('Error in contentPaper: ' + e.message)
      })
    },
    updateContentPaper: (state, payload) => {
      let updateContentPaper = firebase.functions().httpsCallable("updateContentPaper");
      updateContentPaper(payload).then(result => {
        state.contentPaper = result.data.contentPaper

      }).catch((e) => {
        console.log('Error in updateContentPaper: ' + e.message)
      })
    },
    //Liedjesfestival
    loadLiedjesParticipants: (state) => {
      state.liedjesParticipants = null
      let loadLiedjesParticipants = firebase.functions().httpsCallable("loadParticipants");
      loadLiedjesParticipants().then(result => {
        if (result.data.succes == true) {
          state.liedjesParticipants = result.data.participants
        } else {
          console.log("error: " + result.msg)
        }
      }).catch(r => {
        console.log("error in loadliedjesParticipants" + r.message)
      })
    },
    updateParticipants: (state, payload) => {
      try {
        let updateParticipants = firebase.functions().httpsCallable("updateParticipants")
        updateParticipants(payload)
      } catch (error) {
        console.log(error.message)
      }

    },

    //Workgroup management
    addWorkgroup: (state, payload) => {
      try {
        state.workgroups = []
        let addWorkgroup = firebase.functions().httpsCallable("addWorkgroup");
        addWorkgroup(payload).then(result => {
          if (result.data.succes) {
            state.workgroups = result.data.workgroups
            state.users = result.data.users
            state.userWorkgroups = []
            for (let i = 0; i < state.currentUser.memberOf.length; i++) {
              for (let j = 0; j < state.workgroups.length; j++) {
                if (
                  state.currentUser.memberOf[i] ===
                  state.workgroups[j].index
                ) {
                  state.userWorkgroups.push(state.workgroups[j]);
                }
              }
            }
          }
        })
      } catch (error) {
        console.log("error in addWorkgroup: " + error.message)
      }
    },
    loadWorkgroups: (state) => {
      try {
        state.workgroups = []
        let loadWorkgroups = firebase.functions().httpsCallable("loadWorkgroups");
        loadWorkgroups().then(result => {
          if (result.data.succes) {
            state.workgroups = result.data.workgroups
            state.userWorkgroups = []
            for (let i = 0; i < state.currentUser.memberOf.length; i++) {
              for (let j = 0; j < state.workgroups.length; j++) {
                if (
                  state.currentUser.memberOf[i] ===
                  state.workgroups[j].index
                ) {
                  state.userWorkgroups.push(state.workgroups[j]);
                }
              }
            }

          } else {
            console.log("failed")
          }
        })
      } catch (error) {
        console.log("error in loadWorkgroups: " + error.message)
      }
    },
    loadWorkgroupContent: (state, payload) => {
      state.workgroup = null
      let loadWorkgroupContent = firebase.functions().httpsCallable("loadWorkgroupContent");
      loadWorkgroupContent({ user_id: state.currentUser.id, workgroup_id: payload }).then(result => {
        if (result.data.succes == true) {
          state.workgroup = result.data.workgroup
        } else {
          console.log("error: " + result.msg)
        }
      }).catch(r => {
        console.log("error in loadWorkgroupContent " + r.message)
      })
    },
    saveWorkgroupContent: (state, payload) => {
      let saveWorkgroupContent = firebase.functions().httpsCallable("saveWorkgroupContent");
      saveWorkgroupContent({ user_id: state.currentUser.id, content: payload }).then(result => {
        if (result.data.succes == true) {
          state.workgroup = result.data.workgroup
        } else {
          console.log("error: " + result.msg)
        }
      }).catch(r => {
        console.log("error in saveWorkgroupContent " + r.message)
      })
    },
    //secretary management
    loadCalendar: (state) => {
      let loadCalendar = firebase.functions().httpsCallable("loadCalendar");
      loadCalendar().then(result => {
        state.calendar = result.data.calendar.dates
      }).catch(r => {
        console.log("error in loadCalendar " + r.message)
      })
    },
    saveCalendar: (state) => {
      let saveCalendar = firebase.functions().httpsCallable("saveCalendar");

      saveCalendar({ calendar: state.calendar }).then(result => {
        if (result.data.succes == true) {
          state.calendar = result.data.calendar.dates
        }
        else {
          console.log("error: " + result.msg)
        }
      }).catch(r => {
        console.log("error in saveCalendar " + r.message)
      })
    },
    loadSecretary: (state) => {
      state.secretary = null
      let loadSecretary = firebase.functions().httpsCallable("loadSecretary");
      loadSecretary().then(result => {
        if (result.data.succes == true) {
          state.secretary = result.data.secretary
        } else {
          console.log("error: " + result.msg)
        }
      }).catch(r => {
        console.log("error in loadSecretary" + r.message)
      })
    },
    saveSecretary: (state) => {
      let saveSecretary = firebase.functions().httpsCallable("saveSecretary");
      saveSecretary({ secretary: state.secretary }).then(result => {
        if (result.data.succes == true) {
          return
        } else {
          console.log("error: " + result.msg)
        }
      }).catch(r => {
        console.log("error in saveSecretary " + r.message)
      })
    },
    //Acountancy
    loadAccountancy: (state) => {
      state.accountancy = null
      let loadAccountancy = firebase.functions().httpsCallable("loadAccountancy");
      loadAccountancy().then(result => {
        if (result.data.succes == true) {
          state.accountancy = result.data.accountancy
          state.accountancy.years.sort((a, b) => {
            return b.index - a.index;
          });
        } else {
          console.log("error: " + result.msg)
        }
      }).catch(r => {
        console.log("error in loadAccountancy" + r.message)
      })
    },
    loadAccountancyArchive: (state) => {
      state.accountancyArchive = null
      let loadAccountancy = firebase.functions().httpsCallable("loadArchivedAccountancy");
      loadAccountancy().then(result => {
        if (result.data.succes == true) {
          state.accountancyArchive = result.data.accountancy
          state.accountancyArchive.years.sort((a, b) => {
            return b.index - a.index;
          });
        } else {
          console.log("error: " + result.msg)
        }
      }).catch(r => {
        console.log("error in loadAccountancy" + r.message)
      })
    },
    addAccountancyYear: (state, payload) => {
      let addAccountancyYear = firebase.functions().httpsCallable("addAccountancyYear")
      addAccountancyYear(payload).then(result => {
        if (result.data.succes == true) {
          state.accountancy = result.data.accountancy
        } else {
          console.log("error in addAccountancyYear" + result.msg)
        }
      }).catch(r => {
        console.log("error in AddAccountancyYear " + r.message)
      })
    },
    saveAccountancy: (state) => {
      let saveAccountancy = firebase.functions().httpsCallable("saveAccountancy");
      saveAccountancy({ accountancy: state.accountancy }).then(result => {
        if (result.data.succes == true) {
          state.accountancy = result.data.accountancy
          state.accountancy.years.sort((a, b) => {
            return b.index - a.index;
          });
        } else {
          console.log("error: " + result.msg)
        }
      }).catch(r => {
        console.log("error in saveAccountancy " + r.message)
      })
    },
    addDeclaration: (state, payload) => {
      let addDeclaration = firebase.functions().httpsCallable("addDeclaration")
      addDeclaration(payload).then(result => {
        if (result.data.succes == true) {
          state.accountancy = result.data.accountancy
        } else {
          console.log("error in addDeclaration" + result.msg)
        }
      }).catch(r => {
        console.log("error in addDeclaration " + r.message)
      })
    },
    sendTransactionDecision: (state, payload) => {
      try {
        let sendTransactionDecision = firebase.functions().httpsCallable("sendTransactionDecision")
        sendTransactionDecision(payload)
      } catch (error) {
        console.log(error.message)
      }
    },
    //Club111
    addClubMember: (state, payload) => {
      let addClubMember = firebase.functions().httpsCallable("addClubMember")
      addClubMember(payload).then(result => {
        if (result.data.succes) {
          state.clubMembers = result.data.members
          state.clubEditions = result.data.clubEditions
        } else {
          alert(result.data.msg)
        }
      }).catch(r => {
        console.log("error in addClubMember" + r.message)
      })
    },
    loadClubMembers: (state) => {
      state.clubMembers = []
      let loadClubMembers = firebase.functions().httpsCallable("loadClubMembers");
      loadClubMembers().then(result => {
        state.clubMembers = result.data.clubMembers
        let activeEdition = null;
        if (state.clubEditions.editions) {
          state.clubEditions.editions.forEach((x) => {
            if (x.active) {
              if (x.members !== state.clubMembers.length) {
                x.members = state.clubMembers.length
                let saveClubEditions = firebase.functions().httpsCallable("saveClubEditions");
                saveClubEditions({ clubEditions: state.clubEditions }).then(result => {
                  if (result.data.clubEditions) {
                    state.clubEditions = result.data.clubEditions
                  }
                }).catch((e) => {
                  console.log('Error in saveClubEditions: ' + e.message)
                })
              }
              activeEdition = parseInt(x.title);
            }
          });
        }

        state.jubilee11 = 0
        state.jubilee22 = 0
        state.jubilee33 = 0
        state.jubilee44 = 0
        state.jubilee55 = 0
        for (let i = 0; i < state.clubMembers.length; i++) {
          if ('todo' in state.clubMembers[i]) {
            if (state.clubMembers[i].todo.length == 0) {
              delete state.clubMembers[i].todo
            }
          }

          if (state.clubMembers[i].paidYears.includes(activeEdition)) {
            if (state.clubMembers[i].paidYears.length % 11 == 0) {
              if (state.clubMembers[i].paidYears.length / 11 == 1) {
                state.jubilee11++;
              } else if (state.clubMembers[i].paidYears.length / 11 == 2) {
                state.jubilee22++;
              } else if (state.clubMembers[i].paidYears.length / 11 == 3) {
                state.jubilee33++;
              } else if (state.clubMembers[i].paidYears.length / 11 == 4) {
                state.jubilee44++;
              } else if (state.clubMembers[i].paidYears.length / 11 == 5) {
                state.jubilee55++;
              }
              state.clubMembers[i].jubilee = true;
              state.clubMembers[i].jubileeCount =
                state.clubMembers[i].paidYears.length;
            } else {
              state.clubMembers[i].jubilee = false
              delete state.clubMembers[i].jubileeCount
            }
          } else if ((state.clubMembers[i].paidYears.length + 1) % 11 == 0) {
            if ((state.clubMembers[i].paidYears.length + 1) / 11 == 1) {
              state.jubilee11++;
            } else if ((state.clubMembers[i].paidYears.length + 1) / 11 == 2) {
              state.jubilee22++;
            } else if ((state.clubMembers[i].paidYears.length + 1) / 11 == 3) {
              state.jubilee33++;
            } else if ((state.clubMembers[i].paidYears.length + 1) / 11 == 4) {
              state.jubilee44++;
            } else if ((state.clubMembers[i].paidYears.length + 1) / 11 == 5) {
              state.jubilee55++;
            }
            state.clubMembers[i].jubilee = true;
            state.clubMembers[i].jubileeCount =
              state.clubMembers[i].paidYears.length + 1;
          } else {
            state.clubMembers[i].jubilee = false;
            delete state.clubMembers[i].jubileeCount
          }
        }
      }).catch((e) => {
        console.log('Error in loadClubMembers: ' + e.message)
      })
    },
    saveClubMember: (state, payload) => {
      let saveClubMember = firebase.functions().httpsCallable("saveClubMember")
      saveClubMember(payload).then(result => {
        if (result.data.succes) {
          return
        } else {
          alert(result.data.msg)
        }
      }).catch(r => {
        console.log("error in saveClubMember" + r.message)
      })
    },
    addClubEdition: (state, payload) => {
      let addClubEdition = firebase.functions().httpsCallable("addClubEdition")
      addClubEdition(payload).then(result => {
        if (result.data.succes == true) {
          state.clubEditions = result.data.clubEditions
        } else {
          alert("error in addClubEditions" + result.msg)
        }
      }).catch(r => {
        console.log("error in AddclubEditionsYear " + r.message)
      })
    },
    loadClubEditions: (state) => {
      state.clubEditions = []
      let loadClubEditions = firebase.functions().httpsCallable("loadClubEditions");
      loadClubEditions().then(result => {
        if (result.data.clubEditions) {
          state.clubEditions = result.data.clubEditions
        }
      }).catch((e) => {
        console.log('Error in loadClubEditions: ' + e.message)
      })
    },
    saveClubEditions: (state, payload) => {
      let saveClubEditions = firebase.functions().httpsCallable("saveClubEditions");
      saveClubEditions(payload).then(result => {
        if (result.data.clubEditions) {
          state.clubEditions = result.data.clubEditions
        }
      }).catch((e) => {
        console.log('Error in saveClubEditions: ' + e.message)
      })
    },
    executeContactMoment: (state, payload) => {
      let executeContactMoment = firebase.functions().httpsCallable('executeContactMoment')
      executeContactMoment(payload).then(result => {
        if (result.data.succes) {
          state.clubEditions = []
          state.clubEditions = result.data.clubEditions
        } else {
          alert("Genereren van de brieven is mislukt: " + result.data.errorMessage)
        }
      })
    },
    checkContactMomentPayments: (state, payload) => {
      let checkContactMomentPayments = firebase.functions().httpsCallable('checkContactMomentPayments')
      checkContactMomentPayments(payload).then(result => {
        if (result.data.succes) {
          console.log("check geslaagd. Mail met resultaten is verstuurd naar club111@ahc..")
        } else {
          alert("Checken van de betalingen is mislukt: " + result.data.errorMessage)
        }
      })
    },


    ///////////////////Webshop////////////////////////////
    loadWebshop: (state) => {
      let loadWebshop = firebase.functions().httpsCallable('loadWebshop')
      loadWebshop().then(result => {
        if (result.data.succes) {
          state.webshop = result.data.webshop
        } else {
          alert("Inladen webshop is mislukt: " + result.data.errorMessage)
        }
      })
    },
    loadProductImages: (state) => {
      state.productImages = []
      let storageRef = firebase.storage().ref("images/products/");
      storageRef.listAll().then((result) => {
        result.items.forEach((imageRef) => {
          imageRef.getDownloadURL().then((url) => {
            let id = JSON.stringify(imageRef).slice(905, 918)
            state.productImages[id] = url
          })
        });
      }).catch(function (error) {
        console.log(error)
      });

    },
    saveWebshop: (state) => {
      if (state.webshop) {
        let saveWebshop = firebase.functions().httpsCallable('saveWebshop')
        saveWebshop({ webshop: state.webshop }).then(result => {
          if (result.data.succes) {
            return
          } else {
            alert("Wijziging in webshop opslaan is mislukt: " + result.data.errorMessage)
          }
        })
      } else {
        alert("Wijziging in webshop opslaan is mislukt want want het object 'webshop' is null")
      }
    },
    //Pagadder (temporary)
    loadPagadderSuggestions: (state) => {
      try {
        let loadPagadderSuggestions = firebase.functions().httpsCallable('loadPagadderSuggestions')
        loadPagadderSuggestions().then(result => {
          state.pagadderSuggestions = result.data.pagadderSuggestions
        })
      } catch (error) {
        console.log("error in loadPagadderSuggestions: " + error.message)
      }
    },
    ////////////////////////////paper fac mod ////////////////////////////////////
    addPaperFac: (state, payload) => {
      let addPaperFac = firebase.functions().httpsCallable("addPaperFac")
      addPaperFac(payload).then(result => {
        if (result.data.succes) {
          state.paperFacs = result.data.facs
        } else {
          alert(result.data.msg)
        }
      }).catch(r => {
        console.log("error in addPaperFac" + r.message)
      })
    },
    loadPaperFacs: (state) => {
      state.paperFacs = []
      let loadPaperFacs = firebase.functions().httpsCallable("loadPaperFacs");
      loadPaperFacs().then(result => {
        state.paperFacs = result.data.facs
      }).catch((e) => {
        console.log('Error in loadPaperFacs: ' + e.message)
      })
    },
    saveEditedFac: (state, payload) => {
      let saveEditedFac = firebase.functions().httpsCallable("addPaperFac")
      saveEditedFac(payload).then(result => {
        if (result.data.succes) {
          return
        } else {
          alert(result.data.msg)
        }
      }).catch(r => {
        console.log("error in saveEditedFac" + r.message)
      })
    },
    sendPaperFacs: () => {
      try {
        let sendPaperFacs = firebase.functions().httpsCallable("sendPaperFacs")
        sendPaperFacs().then(result => {
          if (result.data.succes) {
            return
          } else {
            alert(result.data.msg)
          }
        }).catch(r => {
          console.log("error in sendPaperFacs" + r.message)
        })
      } catch (error) {
        console.log(error.message)
      }
    },
    ////////////////////////////Brewery mod ////////////////////////////////////
    addBrewery: (state, payload) => {
      let addBrewery = firebase.functions().httpsCallable("addBrewery")
      addBrewery(payload).then(result => {
        if (result.data.succes) {
          state.breweries = result.data.breweries
        } else {
          alert(result.data.msg)
        }
      }).catch(r => {
        console.log("error in addBrewery" + r.message)
      })
    },
    loadBreweries: (state) => {
      state.breweries = []
      let loadBreweries = firebase.functions().httpsCallable("loadBreweries");
      loadBreweries().then(result => {
        state.breweries = result.data.breweries
      }).catch((e) => {
        console.log('Error in loadBreweries: ' + e.message)
      })
    },
    saveEditedBrewery: (state, payload) => {
      let saveEditedBrewery = firebase.functions().httpsCallable("addBrewery")
      saveEditedBrewery(payload).then(result => {
        if (result.data.succes) {
          return
        } else {
          alert(result.data.msg)
        }
      }).catch(r => {
        console.log("error in saveEditedBrewery" + r.message)
      })
    },
    deleteBrewery: (state, payload) => {
      let deleteBrewery = firebase.functions().httpsCallable("deleteBrewery")
      deleteBrewery(payload).then(result => {
        if (result.data.succes) {
          state.breweries = result.data.breweries
        } else {
          alert(result.data.msg)
        }
      }).catch(r => {
        console.log("error in deleteBrewey" + r.message)
      })
    }
  },
  actions: {
    addBrewery: (context, payload) => {
      context.commit('addBrewery', payload)
    },
    loadBreweries: (context) => {
      context.commit('loadBreweries')
    },
    saveEditedBrewery: (context, payload) => {
      context.commit('saveEditedBrewery', payload)
    },
    deleteBrewery: (context, payload) => {
      context.commit('deleteBrewey', payload)
    },
    addPaperFac: (context, payload) => {
      context.commit('addPaperFac', payload)
    },
    loadPaperFacs: (context) => {
      context.commit('loadPaperFacs')
    },
    saveEditedFac: (context, payload) => {
      context.commit('saveEditedFac', payload)
    },
    sendPaperFacs: (context) => {
      context.commit('sendPaperFacs')
    },
    loadUsers: (context) => {
      context.commit('loadUsers')
    },
    addUser: (context, payload) => {
      context.commit('addUser', payload)
    },
    removeUser: (context, payload) => {
      context.commit('removeUser', payload)
    },
    getUser: (context, payload) => {
      context.commit('getUser', payload)
    },
    getCurrentUser: (context, payload) => {
      context.commit('getCurrentUser', payload)
    },
    editUser: (context, payload) => {
      context.commit('editUser', payload)
    },
    clearCurrentUser: (context) => {
      context.commit('clearCurrentUser')
    },
    loadOrders: (context) => {
      context.commit('loadOrders')
    },
    processCollections: (context) => {
      context.commit('processCollections')
    },
    addNewsItem: (context, payload) => {
      context.commit('addNewsItem', payload)
    },
    getNewsItems: (context) => {
      context.commit('getNewsItems')
    },
    loadIntros: (context) => {
      context.commit('loadIntros')
    },
    updateIntros: (context, payload) => {
      context.commit('updateIntros', payload)
    },
    loadContentLiedjes: (context) => {
      context.commit('loadContentLiedjes')
    },
    updateContentLiedjes: (context, payload) => {
      context.commit('updateContentLiedjes', payload)
    },
    loadContentAboutUs: (context) => {
      context.commit('loadContentAboutUs')
    },
    updateContentAboutUs: (context) => {
      context.commit('updateContentAboutUs')
    },
    loadContentHome: (context) => {
      context.commit('loadContentHome')
    },
    updateContentHome: (context) => {
      context.commit('updateContentHome')
    },
    loadContentKids: (context) => {
      context.commit('loadContentKids')
    },
    loadContentJeugdraad: (context) => {
      context.commit('loadContentJeugdraad')
    },
    updateContentJeugdraad: (context, payload) => {
      context.commit('updateContentJeugdraad', payload)
    },
    updateContentKids: (context, payload) => {
      context.commit('updateContentKids', payload)
    },
    updateContentSummer: (context, payload) => {
      context.commit('updateContentSummer', payload)
    },
    loadContentSummer: (context) => {
      context.commit('loadContentSummer')
    },
    loadContentPaper: (context) => {
      context.commit('loadContentPaper')
    },
    updateContentPaper: (context, payload) => {
      context.commit('updateContentPaper', payload)
    },
    loadLiedjesParticipants: (context) => {
      context.commit('loadLiedjesParticipants')
    },
    updateParticipants: (context, payload) => {
      context.commit("updateParticipants", payload)
    },
    deleteNewsItem: (context, payload) => {
      context.commit('deleteNewsItem', payload)
    },
    getNewsImages: (context) => {
      context.commit('getNewsImages')
    },
    editProfile: (context, payload) => {
      context.commit('editProfile', payload)
    },
    getContactAdresses: (context) => {
      context.commit('getContactAdresses')
    },
    editContactAdresses: (context, payload) => {
      context.commit('editContactAdresses', payload)
    },
    sendProblem: (context, payload) => {
      context.commit('sendProblem', payload)
    },
    addWorkgroup: (context, payload) => {
      context.commit('addWorkgroup', payload)
    },
    loadWorkgroups: (context) => {
      context.commit('loadWorkgroups')
    },
    loadWorkgroupContent: (context, payload) => {
      context.commit('loadWorkgroupContent', payload)
    },
    saveWorkgroupContent: (context, payload) => {
      context.commit('saveWorkgroupContent', payload)
    },
    saveCalendar: (context) => {
      context.commit('saveCalendar')
    },
    loadCalendar: (context) => {
      context.commit('loadCalendar')
    },
    loadSecretary: (context) => {
      context.commit('loadSecretary')
    },
    saveSecretary: (context) => {
      context.commit('saveSecretary')
    },
    loadAccountancy: (context) => {
      context.commit('loadAccountancy')
    },
    loadAccountancyArchive: (context) => {
      context.commit('loadAccountancyArchive')
    },
    addAccountancyYear: (context, payload) => {
      context.commit('addAccountancyYear', payload)
    },
    saveAccountancy: (context) => {
      context.commit('saveAccountancy')
    },
    addDeclaration: (context, payload) => {
      context.commit('addDeclaration', payload)
    },
    sendTransactionDecision: (context, payload) => {
      context.commit('sendTransactionDecision', payload)
    },
    addClubMember: (context, payload) => {
      context.commit('addClubMember', payload)
    },
    loadClubMembers: (context) => {
      context.commit("loadClubMembers")
    },
    saveClubMember: (context, payload) => {
      context.commit("saveClubMember", payload)
    },
    addClubEdition: (context, payload) => {
      context.commit('addClubEdition', payload)
    },
    loadClubEditions: (context) => {
      context.commit("loadClubEditions")
    },
    saveClubEditions: (context, payload) => {
      context.commit('saveClubEditions', payload)
    },
    executeContactMoment: (context, payload) => {
      context.commit('executeContactMoment', payload)
    },
    loadWebshop: (context) => {
      context.commit('loadWebshop')
    },
    loadProductImages: (context) => {
      context.commit('loadProductImages')
    },
    saveWebshop: (context) => {
      context.commit('saveWebshop')
    },
    loadPagadderSuggestions: (context) => {
      context.commit('loadPagadderSuggestions')
    },
    checkContactMomentPayments: (context, payload) => {
      context.commit('checkContactMomentPayments', payload)
    }

  },
})
